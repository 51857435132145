import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import {AssessmentIcon} from '../components/svgs';
import { Alert, DefaultSelect, Loader } from '../components/ui';
import { ButtonDefS } from '../components/ui/buttons/styles';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { API_ROUTE_PATH } from '../utils/api_routes';
import { callApi } from '../utils/callApi';
import { TEXT } from '../utils/textConst';
import {useGroupReport} from "../hooks/useGroupReport";
import {XlsxIcon} from "../components/svgs/XlsxIcon";
import {COLORS} from "../utils/colors";
import {ParticipantSources} from "../store/groups/types";
import {ALL_GROUPS_TYPES} from "../utils/consts";
import {deepClone} from "../utils/deepClone";
interface GroupReportProps {}

export const GroupReport: React.FC<GroupReportProps> = () => {
  const { Cats, Workspaces } = useSelector((store: AppStore) => store);
  const [errorText, setErrorText] = React.useState('');
  const [selectPackage, setSelectPackage] = React.useState<SelectItemType[]>([]);
  const [selectCycle, setSelectCycle] = React.useState<SelectItemType[]>([]);
  const [isLoadingOriginSource, setIsLoadingOriginSource] = React.useState(true);
  const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
  const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);
  const [step, setStep] = React.useState(1);
  const dispatch = useDispatch();
  const {
    setSelectOriginSource,
    selectOriginSource,
    selectedOriginSource,
    selectedPackage,
    selectedCycle,
    setSelectedOriginSource,
    setSelectedCycle,
    setSelectedPackage,
    genXlsx,
    isLoading
  } = useGroupReport();

  React.useEffect(() => {
    const callFn = async () => {
      if (!Workspaces.current!.id)
        return setErrorText('No Workspaces id, ' + TEXT.tryLater);
      try {

        setSelectOriginSource([ALL_GROUPS_TYPES]);
        setSelectedOriginSource([ALL_GROUPS_TYPES])
        setSelectPackage(null);
        setSelectedPackage(null)
        setSelectCycle(null);
        setSelectedCycle(null);
        setIsLoadingPackage(true);
        setStep(1)
        const resp = (await callApi({
          path: `${API_ROUTE_PATH.packages.getAnsweredByWorkspace(
              Workspaces.current!.id
          )}`,
          method: 'get',
        })) as {
          id: string;
          name: string;
        }[];
        if (!Array.isArray(resp))
          return setErrorText('Wrang format API data, ' + TEXT.tryLater);
        const newSelectData: SelectItemType[] = [];

        resp.forEach((itm) => {
          let name = itm.name;
          if (
              resp.find(
                  (pkg) => pkg.name === itm.name && pkg.id !== itm.id
              )
          )
            name = `${itm.name}`;
          newSelectData.push({
            title: name,
            value: itm.id,
            id: itm.id,
          });
        });

        setSelectPackage(newSelectData);
      } catch (error) {
        setErrorText(`${error}`);
      } finally {
        setIsLoadingPackage(false);
      }
    };

    if (Workspaces.current) {
      callFn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Workspaces.current, dispatch]);

  const callOriginSources = async (item: SelectItemType) => {
    if (!Workspaces.current!.id)
      return setErrorText('No Workspaces id, ' + TEXT.tryLater);
    if (!item?.id)
      return setErrorText('No select Origin Source, ' + TEXT.tryLater);

    try {
      setIsLoadingOriginSource(true);
      setSelectedCycle(item)
      setSelectedOriginSource([ALL_GROUPS_TYPES])
      setStep(3)
      const resp = (await callApi({
        path: `${API_ROUTE_PATH.groups.gOriginSourceAnsweredByWorkspaceID(
            Workspaces.current!.id
        )}`,
        method: 'post',
        data: {
          package_id: selectedPackage.id,
          link: item.id,
          is_all: false
        }
      })) as {
        origin_sources: string[];
      };

      if (!Array.isArray(resp.origin_sources))
        return setErrorText('Wrang format API data, ' + TEXT.tryLater);
      const newSelectData: SelectItemType[] = [ALL_GROUPS_TYPES];

      resp.origin_sources.forEach((itm) => {
        newSelectData.push({
          title: ParticipantSources[itm],
          value: itm,
          id: itm,
        });
      });

      setSelectOriginSource(newSelectData);
    } catch (error) {
      setErrorText(`${error}`);
    } finally {
      setIsLoadingOriginSource(false);
    }
  };


  const callCycles = async (item:  SelectItemType) => {
    if (!Workspaces.current!.id)
      return setErrorText('No Workspaces id, ' + TEXT.tryLater);
    if (!item?.id)
      return setErrorText('No Package id, ' + TEXT.tryLater);
    try {
      setIsLoadingCycle(true);
      setSelectedPackage(item)
      setSelectedCycle(null)
      setStep(2)
      const resp = (await callApi({
        path: `${API_ROUTE_PATH.packages.getAnsweredCyclesByWorkspace(
            Workspaces.current!.id,
            item.id as string,
            '00000000-0000-0000-0000-000000000000',
        )}`,
        method: 'get',
      })) as {
        from: string;
        to: string;
        is_forever_package: boolean;
        link: string;
      }[];

      if (!Array.isArray(resp))
        return setErrorText('Wrang format API data, ' + TEXT.tryLater);

      const newCycles: SelectItemType[] = [];
      var links: string[] = [];
      resp.forEach((itm) => {
        const dateFrom = new Date(itm.from);
        const dateTo = new Date(itm.to);
        const dateNow = new Date();
        const dateNull = new Date('0001-01-01T00:00:00Z');

        const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
        const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`

        let dateString = dateFromString

        if ((dateNow < dateTo) || (dateTo.getTime() === dateNull.getTime() && itm.is_forever_package)) {
          dateString += ' to current'
        }
        if (dateNow > dateTo && (dateTo.getTime() !== dateNull.getTime() && !itm.is_forever_package)) {
          dateString += ' to ' + dateToString;
        }

        newCycles.push({
          title: dateString,
          value: itm.link,
          id: itm.link,
        });
        links.push(itm.link)
      });

      setSelectCycle(newCycles);
    } catch (error) {
      setErrorText(`${error}`);
    } finally {
      setIsLoadingCycle(false);
    }
  };

  const originSourceSelectHandler = (data: SelectItemType) => {
    if (
        data.value === ALL_GROUPS_TYPES.value &&
        selectedOriginSource[0].value === ALL_GROUPS_TYPES.value
    ) {
      return null;
    }
    const newData = deepClone(selectedOriginSource) as SelectItemType[];
    const originSources: SelectItemType[] = [];

    if (data.value === ALL_GROUPS_TYPES.value) {
      setSelectedOriginSource([ALL_GROUPS_TYPES]);
      setSelectedOriginSource([ALL_GROUPS_TYPES])
      return
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_GROUPS_TYPES.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_GROUPS_TYPES.value) {
        originSources.push(itm);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_GROUPS_TYPES);
    }

    setSelectedOriginSource(newData);
    return
  };

  const render = () => {
    if (errorText) {
      return (
          <div style={{ marginTop: '20px' }}>
            <Alert text={errorText} />
          </div>
      );
    }
    // if (isDataLoading) {
    //   return <Loader isGreen />;
    // }

    if (!Cats.data && !Cats.loading) {
      return (
          <div style={{ marginTop: '20px' }}>
            <Alert text="No assessments" type="warning" />
          </div>
      );
    }
    return (
        <div style={{ position: 'relative' }}>
          <SelectWrapperS>
            {step >= 1&&
                <DefaultSelect
                    data={selectPackage}
                    icon={AssessmentIcon}
                    selected={selectedPackage}
                    label="Choose Package"
                    onChange={(e) => {
                      callCycles(e)
                    }}
                    placeHolder="Choose Package"
                    isLoading={isLoadingPackage}
                />
            }
            {step >= 2&&
                <DefaultSelect
                    data={selectCycle}
                    label="Choose Cycle"
                    placeHolder="Choose Cycle"
                    icon={AssessmentIcon}
                    selected={ selectedCycle }
                    onChange={callOriginSources}
                    isLoading={isLoadingCycle}
                />
            }
            {step === 3&&
                <DefaultSelect
                    data={selectOriginSource}
                    label="Choose Group Types"
                    placeHolder="Choose Group Types"
                    icon={AssessmentIcon}
                    selected={ selectOriginSource[0]}
                    onChange={originSourceSelectHandler}
                    activeSelects={selectedOriginSource?.length !== 0 ? selectedOriginSource : [] }
                    isMultiple
                    isLoading={isLoadingOriginSource}
                />
            }
          </SelectWrapperS>
          <ButtonDefS
              onClick={!isLoading ? () => genXlsx() : () => null}
              style={{ maxWidth: '97px' }}
              disabled={!selectedOriginSource}
          >
            {isLoading ? <Loader size={0.5} /> : 'Export'}
          </ButtonDefS>
        </div>
    );
  };

  return (
      <>
        <MainWrapperS>
          <PageTitleWrapperS>
            <h1>Group Report</h1>
          </PageTitleWrapperS>
          <WrapperTitleIconS>
            <XlsxIcon />
            <h3>Export XLSX File</h3>
            <InfoIS>
              i
              <div>
                <div>
                  To load the file with the group report, please select the package (from the list of packages with answers), and then select the cycle from the list of cycles with answers (in the dropdown you can see the start dates of the cycles for choosing). Select the group types if you prefer to have only some of them on separate pages. </div>
              </div>
            </InfoIS>
          </WrapperTitleIconS>
          {render()}
        </MainWrapperS>
      </>
  );
};

const InfoIS = styled.div`
  font-size: 12px;
  position: relative;
  left: 10px;
  top: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: ${COLORS.accent};
  color: white;
  text-align: center;
  margin-left: 2px;
  border-radius: 10px;
  z-index: 3;

  & > div {
    background-color: white;
    font-weight: normal;
    font-size: 14px;
    display: none;
    position: absolute;
    top: 200%;
    width: 250px;
    left: -75px;
    border-radius: 10px;
    box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
    border: solid 1px rgba(162, 165, 162, 0.15);
    color: ${COLORS.default};
    text-align: left;
    padding: 15px;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`;

const WrapperTitleIconS = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.6rem;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #171717;
  }
`;

const SelectWrapperS = styled.div`
  width: 100%;
  margin: 24px 0;
  max-width: 328px;

  & > * {
    margin-bottom: 15px;
  }

  .svgIconSelect {
    opacity: 0.3;
  }
`;

